import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import Vue from "vue";
import router from "@/router";
import axios from "axios";
import store from "@/core/services/store";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { validationError } from "@/core/lib/common.lib";
import { ERROR } from "@/core/services/store/common.module";
/**
 * Service to call HTTP request via Axios
 */

var ApiService = {
  init: function init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.defaults.headers.common["Accept"] = "application/json"; // Vue.axios.defaults.headers.common["Content-Type"] = "application/json";

    Vue.axios.defaults.trailingSlash = false; // Add a request interceptor

    Vue.axios.interceptors.request.use(function (config) {
      if (config.url.endsWith("/")) {
        config.url = config.url.slice(0, -1);
      }

      return config;
    }, function (error) {
      return Promise.reject(error);
    }); // Add a response interceptor

    Vue.axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      var response = error.response;

      if (response.status == 422) {
        var errors = validationError(response);
        store.dispatch(ERROR, errors);
      } else if (response.status == 401) {
        JwtService.destroyToken();
        var resolved = router.resolve({
          name: "login"
        });
        window.location.href = resolved.href;
      } else {
        if (response.data && response.data.message) {
          store.dispatch(ERROR, [{
            model: true,
            message: response.data.message,
            timeout: 2000
          }]);
        } else {
          store.dispatch(ERROR, [{
            model: true,
            message: "Something went wrong. Please try again later.",
            timeout: 2000
          }]);
        }
      }

      return Promise.reject(error);
    });
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader: function setHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = "".concat(JwtService.getTokenType(), " ").concat(JwtService.getToken());
  },
  query: function query(resource, params) {
    return new Promise(function (resolve, reject) {
      Vue.axios.get(resource, {
        params: params
      }).then(function (_ref) {
        var data = _ref.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get: function get(resource) {
    var slug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    return new Promise(function (resolve, reject) {
      Vue.axios.get("".concat(resource, "/").concat(slug)).then(function (_ref2) {
        var data = _ref2.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post: function post(resource, params) {
    return new Promise(function (resolve, reject) {
      Vue.axios.post(resource, params).then(function (_ref3) {
        var data = _ref3.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update: function update(resource, slug, params) {
    return new Promise(function (resolve, reject) {
      Vue.axios.put("".concat(resource, "/").concat(slug), params).then(function (_ref4) {
        var data = _ref4.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put: function put(resource, params) {
    return new Promise(function (resolve, reject) {
      Vue.axios.put(resource, params).then(function (_ref5) {
        var data = _ref5.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch: function patch(resource, params) {
    return new Promise(function (resolve, reject) {
      Vue.axios.patch(resource, params).then(function (_ref6) {
        var data = _ref6.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete: function _delete(resource) {
    return new Promise(function (resolve, reject) {
      Vue.axios.delete(resource).then(function (_ref7) {
        var data = _ref7.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },

  /**
   * Send the UPLOAD HTTP request
   * @param resource
   * @returns {*}
   */
  upload: function upload(resource, params) {
    return new Promise(function (resolve, reject) {
      Vue.axios.post(resource, params, {
        headers: {
          "content-type": "multipart/form-data"
        }
      }).then(function (_ref8) {
        var data = _ref8.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default ApiService;