import "D:\\Development\\Laravel\\genic-drive-updated\\frontend\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\Development\\Laravel\\genic-drive-updated\\frontend\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\Development\\Laravel\\genic-drive-updated\\frontend\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\Development\\Laravel\\genic-drive-updated\\frontend\\node_modules\\core-js\\modules\\es.promise.finally.js";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service"; // import { VERIFY_AUTH } from '@/core/services/store/auth.module';

import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import MainSetting from "@/core/mixins/main.mixin";
import VueMask from "v-mask";
import PortalVue from "portal-vue";
import Pusher from "pusher-js";
import { toLower } from "lodash";
Vue.use(VueMask);
Vue.use(PortalVue);
Vue.use(MainSetting);
var pusher = new Pusher("0d36c53eb43c02fd54b4", {
  cluster: "ap1"
});
Vue.prototype.$pusher = pusher.subscribe("download-folder");
Vue.config.productionTip = false;
Vue.prototype.$APPNAME = process.env.VUE_APP_NAME;
Vue.prototype.$APP_CLIENT_NAME = process.env.VUE_APP_CLIENT_NAME;
Vue.prototype.$apiURL = process.env.VUE_APP_API_URL;
Vue.prototype.$messageTimeout = process.env.VUE_APP_MESSAGE_TIMEOUT;
Vue.prototype.$uploadLIMIT = process.env.VUE_APP_FILE_UPLOAD_LIMIT;
Vue.prototype.$uploadSizeLIMIT = process.env.VUE_APP_FILE_SIZE_LIMIT;
Vue.prototype.$timezone = process.env.VUE_APP_TIMEZONE;
Vue.prototype.$enc_key = process.env.VUE_APP_ENCRYPTION_KEY;
Vue.prototype.$licenseCode = process.env.VUE_APP_LICENSE_CODE;

Vue.prototype.$assetURL = function (param) {
  return toLower(process.env.VUE_APP_BASE_URL + param);
}; // Global 3rd party plugins


import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar; // Vue 3rd party plugins

import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css"; // API service init

ApiService.init();
router.beforeEach(function (to, from, next) {
  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  next(); // reset config to initial state

  store.dispatch(RESET_LAYOUT_CONFIG); // Scroll page to top on every route change

  setTimeout(function () {
    window.scrollTo(0, 0);
  }, 100);
});
new Vue({
  router: router,
  store: store,
  vuetify: vuetify,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");