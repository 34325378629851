import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
export default new Router({
  mode: "history",
  routes: [{
    path: "/",
    redirect: "/dashboard",
    component: function component() {
      return import("@/view/layout/Layout");
    },
    children: [{
      path: "dashboard",
      name: "dashboard",
      component: function component() {
        return import("@/view/pages/Dashboard");
      }
    }, {
      path: "drive",
      name: "mine",
      component: function component() {
        return import("@/view/pages/Drive");
      }
    }, {
      path: "shared-with-me",
      name: "shared-with-me",
      component: function component() {
        return import("@/view/pages/SharedWithMe");
      }
    }, {
      path: "recent",
      name: "recent",
      component: function component() {
        return import("@/view/pages/Recent");
      }
    }, {
      path: "starred",
      name: "starred",
      component: function component() {
        return import("@/view/pages/Starred");
      }
    }, {
      path: "bin",
      name: "bin",
      component: function component() {
        return import("@/view/pages/Bin");
      }
    }, {
      path: "users",
      name: "Users",
      component: function component() {
        return import("@/view/pages/Users");
      }
    }, {
      path: "activity",
      name: "activity",
      component: function component() {
        return import("@/view/pages/ActivityLog");
      }
    }, {
      path: "user-detail",
      name: "UserDetail",
      component: function component() {
        return import("@/view/pages/UserDetail");
      }
    }, {
      path: "tag",
      name: "tag",
      component: function component() {
        return import("@/view/pages/Tags");
      }
    }, {
      path: "tag/:uuid/detail",
      name: "tag-detail",
      component: function component() {
        return import("@/view/pages/Tag-Detail");
      }
    }, {
      path: "setting",
      name: "Setting",
      component: function component() {
        return import("@/view/pages/Setting");
      }
    }, {
      path: "pdf",
      name: "pdf-preview",
      component: function component() {
        return import("@/view/pages/Pdf-Preview");
      }
    }, {
      path: "pdf2",
      name: "pdf2-preview",
      component: function component() {
        return import("@/view/pages/Pdf-Preview2");
      }
    }]
  }, {
    path: "/custom-error",
    name: "error",
    component: function component() {
      return import("@/view/pages/error/Error");
    },
    children: [{
      path: "error-1",
      name: "error-1",
      component: function component() {
        return import("@/view/pages/error/Error-1");
      }
    }]
  }, {
    name: "authenticate",
    path: "/",
    component: function component() {
      return import("@/view/pages/auth/Authenticate");
    },
    children: [{
      path: "login",
      name: "login",
      component: function component() {
        return import("@/view/pages/auth/Login");
      }
    }, {
      path: "reset-password",
      name: "reset-password",
      component: function component() {
        return import("@/view/pages/auth/Reset-Password");
      }
    }]
  }, {
    path: "*",
    redirect: "/404"
  }, {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: function component() {
      return import("@/view/pages/error/Error-1");
    }
  }]
});