import _defineProperty from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _actions;

import "core-js/modules/es.object.to-string.js";
import ApiService from "@/core/services/api.service"; // action types

export var POST = "requestPost";
export var GET = "requestGet";
export var QUERY = "requestQuery";
export var PUT = "requestPut";
export var DELETE = "requestDelete";
export var PATCH = "requestPatch";
export var UPLOAD = "requestUpload"; // mutation types

export var SET_DATA = "setData";
var state = {
  data: [],
  request_errors: []
};
var getters = {
  data: function data(state) {
    state.data;
  }
};
var actions = (_actions = {}, _defineProperty(_actions, POST, function (context, _ref) {
  var url = _ref.url,
      data = _ref.data;
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.post(url, data).then(function (_ref2) {
      var data = _ref2.data;
      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (_ref3) {
      var response = _ref3.response;
      reject(response);
    });
  });
}), _defineProperty(_actions, UPLOAD, function (context, _ref4) {
  var url = _ref4.url,
      data = _ref4.data;
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.upload(url, data).then(function (_ref5) {
      var data = _ref5.data;
      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (_ref6) {
      var response = _ref6.response;
      reject(response);
    });
  });
}), _defineProperty(_actions, QUERY, function (context, _ref7) {
  var url = _ref7.url,
      data = _ref7.data;
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.query(url, data).then(function (_ref8) {
      var data = _ref8.data;
      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (_ref9) {
      var response = _ref9.response;
      reject(response);
    });
  });
}), _defineProperty(_actions, GET, function (context, _ref10) {
  var url = _ref10.url;
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.get(url).then(function (_ref11) {
      var data = _ref11.data;
      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (_ref12) {
      var response = _ref12.response;
      reject(response);
    });
  });
}), _defineProperty(_actions, PUT, function (context, _ref13) {
  var url = _ref13.url,
      data = _ref13.data;
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.put(url, data).then(function (_ref14) {
      var data = _ref14.data;
      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (_ref15) {
      var response = _ref15.response;
      reject(response);
    });
  });
}), _defineProperty(_actions, DELETE, function (context, _ref16) {
  var url = _ref16.url,
      data = _ref16.data;
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.delete(url, data).then(function (_ref17) {
      var data = _ref17.data;
      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (_ref18) {
      var response = _ref18.response;
      reject(response);
    });
  });
}), _defineProperty(_actions, PATCH, function (context, _ref19) {
  var url = _ref19.url,
      data = _ref19.data;
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.patch(url, data).then(function (_ref20) {
      var data = _ref20.data;
      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (_ref21) {
      var response = _ref21.response;
      reject(response);
    });
  });
}), _actions);

var mutations = _defineProperty({}, SET_DATA, function (state, data) {
  state.data = data;
});

export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};